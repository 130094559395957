import React from "react";
import GoogleLoginComponent from "./googlebutton.component";
import { PaginatedList } from "react-paginated-list";
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import './App.css';

// https://www.npmjs.com/package/@react-oauth/google

require('dotenv').config();

class SearchResult extends React.Component {
  render() {
    let uri = encodeURIComponent(this.props.value.name);
    let href = `/api/file/${uri}`
    return (
      <tr>
        <td>
          {this.props.value.id}
        </td>
        <td>
          {this.props.value.name}
        </td>
        <td>
          <audio controls src={href} preload="metadata">
          </audio>
        </td>
        <td className="text-center">
          <a href={href}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
          </a>
        </td>
      </tr>
    );
  }
}

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: '', isLoaded: false, isLoggedIn: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    fetch("/api/search/" + this.state.value, {
      headers: {
        'Authorization': 'Bearer ' + this.state.accessToken
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.items
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  handleLogin(userInfo) {
    fetch('/api/auth/google', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: userInfo.credential })
    })
    .then(response => response.json())
    .then(data => this.setState({isLoggedIn: true, accessToken: data.payload.accessToken, userName: data.payload.name}));
  }

  handleLogout() {
    this.setState({isLoggedIn: false});
  }

  render() {
    const listContainer = styled.tbody``;
    const { error, isLoaded, isLoggedIn, items } = this.state;

    let searchForm = (
      <>
      <h4>Hello, {this.state.userName} &#128075;</h4>
      <Form onSubmit={this.handleSubmit}>
        <Form.Label>
          Search:
          <Form.Control type="text" value={this.state.value} onChange={this.handleChange} />
        </Form.Label>
        <Button variant="primary" type="submit">Submit</Button>
      </Form>
      </>
    );

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div>

          <GoogleLoginComponent 
            onLogin={this.handleLogin}
            onLogout={this.handleLogout} />

          { isLoggedIn && searchForm }
          { isLoggedIn && isLoaded &&
            <>
              <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Controls</th>
                  <th>Download</th>
                </tr>
              </thead>
              <PaginatedList
                  list={items}
                  itemsPerPage={10}
                  PaginatedListContainer={listContainer}
                  renderList={(list) => (
                    <>
                      {list.map((item) => {
                        return (
                          <SearchResult key={item.id} value={item} />
                        );
                      })}
                    </>
                  )}
                />
              </Table>
            </>
          }
        </div>          
      );
    } 
  }
}

function App() {
  const [data, setData] = React.useState(null);

  // React.useEffect(() => {
  //   fetch("/api")
  //     .then((res) => res.json())
  //     .then((data) => setData(data.message));
  // }, []);
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <Container className="p-3">
        <Image src='ass_logo.png'/>
        <SearchForm />
      </Container>
    </GoogleOAuthProvider>
  );
}

export default App;
