import React, { Component } from "react";
import { GoogleLogin } from '@react-oauth/google';

// https://reactjsexample.com/google-oauth2-using-the-new-google-identity-services-sdk-for-react/

class GoogleLoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userInfo: {
        clientId: "",
        credential: "",
      },
    };
  }

  // Success Handler
  responseGoogleSuccess = (response) => {
    let userInfo = {
      clientId: response.clientId,
      credential: response.credential,
    };
    this.setState({ userInfo, isLoggedIn: true });
    this.props.onLogin(userInfo);
  };

  // Error Handler
  responseGoogleError = (response) => {
    console.log('Error response from Google:')
    console.log(response);
  };

  // Logout Session and Update State
  logout = (response) => {
    console.log(response);
    let userInfo = {
      clientId: "",
      credential: "",
    };
    this.setState({ userInfo, isLoggedIn: false });
    this.props.onLogout();
  };

  render() {
    return (      
      <div className="row mt-5">
        <div className="col-md-12">
          {!this.state.isLoggedIn && (
            <GoogleLogin
              onSuccess={this.responseGoogleSuccess}
              onFailure={this.responseGoogleError}
              useOneTap
            />
          )}
        </div>
      </div>
    );
  }
}
export default GoogleLoginComponent;